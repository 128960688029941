import ICalParser from 'ical-js-parser';

const ICAL_URL = "https://p43-caldav.icloud.com/published/2/MTI1MzE4MDQ5MTI1MzE4MJYYd-AWHWUilT39fg2x9TAC3SpQnHflg18jJyGBHBLd0Pq7oIw_fx7D8Po6SJg2YAVckLXt7qfkkfIy0zyNSLc"
const CALENDAR_ELEMENT = "ul#agenda"

const buildAgenda = () => {
    console.log("fetching agenda");

    fetch(`https://api.allorigins.win/raw?url=${encodeURIComponent(ICAL_URL)}`)
    .then(response => {
        console.log(response)
	    if (response.ok) return response.text()
	    throw new Error('Network response was not ok.')
    })
    .then(resp => {
        console.log(resp)
        return ICalParser.toJSON(resp)
    })
    .then(calendar => {
        console.log(calendar)
        const destElement = document.querySelector(CALENDAR_ELEMENT)
        destElement.innerHTML = ""

        calendar.events.sort((a, b) => {
            return parseInt(a.dtstart.value.substring(0, 8)) - parseInt(b.dtstart.value.substring(0, 8));
        })
        .filter((ev) => {
            const evtYear = parseInt(ev.dtstart.value.substring(0, 4), 10);
            const evtMonth = parseInt(ev.dtstart.value.substring(4, 6), 10) - 1;
            const evtDay = parseInt(ev.dtstart.value.substring(6, 8), 10);
            const eventDate = new Date(evtYear, evtMonth, evtDay);
            const now = new Date();

            return eventDate >= new Date(now.getFullYear(), now.getMonth(), now.getDate());
        })
        .forEach(event => {
            const eventName = event.summary.replace(/\\/g, "")
            //const eventStart = iCalDateParser(event.dtstart.value)

            const evtYear = parseInt(event.dtstart.value.substring(0, 4), 10);
            const evtMonth = parseInt(event.dtstart.value.substring(4, 6), 10) -1;
            const evtDay = parseInt(event.dtstart.value.substring(6, 8), 10);

            const eventDate = new Date(evtYear, evtMonth, evtDay)
            const dateOptions = { month: 'short', day: 'numeric' }

            const eventNode = document.createElement('li');
            const eventDateNode = document.createElement('strong')
            const eventFormattedDate = eventDate.toLocaleDateString("en-US", dateOptions)
            eventDateNode.appendChild(document.createTextNode(eventFormattedDate))

            eventNode.appendChild(eventDateNode);
            eventNode.appendChild(document.createTextNode(" - "));
            eventNode.appendChild(document.createTextNode(eventName));
            
            destElement.appendChild(eventNode);
        });
    })
}

addEventListener("load", buildAgenda);